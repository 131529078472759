<template>
  <div class="navbar-container main-menu-content d-flex justify-content-between flex-wrap">
    <horizontal-nav-menu-items :items="navMenuItems" />
    <div
      v-show="isJobExist"
      class="w-50 mt-75"
    >
      <div class="d-flex">
        <div class="bg-danger pt-1 pb-50 px-4">
          <span class="text-white font-semibold inline-block">Jobs</span>
        </div>
        <marquee-text
          :duration="speed"
          :paused="isPaused"
          repeat="0"
          class="pt-25 pb-25 pl-4"
          @mouseenter="isPaused = true"
          @mouseleave="isPaused = false"
        >
          <b-badge
            v-for="(job, index) in jobs"
            :key="index"
            variant="light-primary"
            class="lead mr-1 py-75 px-1 mb-0"
            pill
            @click="gotoJobs()"
          >
            <!-- <vs-avatar icon-pack="feather" color="primary" icon="icon-map-pin" /> -->
            <span class="font-semibold">{{ job.label }}</span>
            <span class="ml-2">
              <b-avatar
                variant="light-primary"
                class="mr-25 b-avatar-custome"
              >
                <feather-icon icon="MapPinIcon" />
              </b-avatar>
              {{ job.location }}
            </span>
          </b-badge>
        </marquee-text>
      </div>
    </div>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import { BBadge, BAvatar } from 'bootstrap-vue'
import Job from '@/js/jobs'
import User from '@/js/user'
import auth from '@/auth/authService'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      speed: 30,
      isPaused: false,
      jobs: [],
    }
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  created() {
    if (auth.isAuthenticated()) {
      if (User.profile() !== null) {
        this.isJobExist = (User.profile().timesheet_provider_parents.length > 0 || User.profile().invoice_parents.length > 0) && User.profile().job_parent.length > 0
        if (this.isJobExist) {
          Job.getFullList(this)
        }
      } else {
        window.location.hash = '#/login'
      }
    } else {
      window.location.hash = '#/login'
    }
  },
  methods: {
    setJobs(jobs) {
      this.jobs = jobs
      this.speed = jobs.length * 2
    },
    gotoJobs() {
      window.location.hash = '#/jobs'
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

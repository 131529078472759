<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderLink(item)"
    class="nav-item"
    :class="[
      {'sidebar-group-active active': isActive},
      {'vs-job--item': classJobs},
    ]"
  >
    <b-link
      v-if="isItemExist(item)"
      class="nav-link"
      :to="{ name: item.route }"
    >
      <feather-icon
        :badge="item.title === 'Jobs' ? jobs_count : interviews_count"
        badge-classes="bg-danger text-jobs-count"
        size="14"
        :icon="item.icon"
      />
      <span>{{ item.title }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import User from '@/js/user'
import Auth from '@/auth/authService'
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink'

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    classJobs: {
      type: String,
      default: null,
    },
    classInterviews: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isPaused: false,
      jobs_count: 0,
      interviews_count: 0,
    }
  },
  created() {
    if (Auth.isAuthenticated()) {
      if (User.profile() !== null) {
        this.interviews_count = User.profile().interviews_count
        this.isJobExist = (User.profile().timesheet_provider_parents.length > 0 || User.profile().invoice_parents.length > 0) && User.profile().job_parent.length > 0
        this.jobs_count = User.profile().jobs_count
      }
    }
  },
  methods: {
    isItemExist(item) {
      if (item.title === 'Timesheets') {
        return User.profile().timesheet_provider_parents.length > 0
      }
      if (item.title === 'Invoices') {
        return User.profile().invoice_parents.length > 0
      }
      if (item.title === 'Jobs') {
        return User.profile().job_parent.length > 0
      }
      if (item.title === 'Requests') {
        return User.profile().timesheet_provider_parents.length > 0 || User.profile().invoice_parents.length > 0 || User.profile().job_parent.length > 0
      }
      if (item.title === 'My Interviews') {
        return User.profile().interviewer_exist
      }
      return false
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item)

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils()

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    }
  },
}
</script>
